<template>
  <!-- 元素下边距margin-bottom -->
  <div class="home-banner" style="margin-bottom:0px">
    <img :src="sliders" alt="" style="width: 100%;">
    <!-- {{sliders}} -->
      <!-- <BaseCarousel auto-play :sliders="sliders" /> -->
    <div class="text">
      <div class="title">
        <!-- <h1>物联网硬件设备及物联网解决方案供应商</h1> -->
      </div>
      <div class="sub">
        <p>
          <!-- 博赛智能物联网研究院是一家以高技物联产品开发为主导，集传感器数据采集网关、工控电脑、车载终端、物联网教具等智能硬件研发、生产、销售、服务于一体的高科技企业。公司拥有一批高级工程技术人才，在移动射频系统、电路设计、PCB Layout、工业设计(ID)、结构设计(MD)、软件设计(SW、测试(TE)、品质保障(QA)等领域拥有丰富的从业经验，可根据客户的需求提供定制的解决方案。 -->
        </p>
      </div>
    </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import { findAd } from '@/api/home'
export default {
  name: 'HomeBanner',
  setup () {
    // 获取轮播图数据
    const sliders = ref([])
    findAd("homeCarousel").then(data => {
      console.log(data) //将简单地将给定的数据，整数，变量，字符串，JSON打印到浏览器控制台
      sliders.value = data.data.list[0].filePath
    })
    console.log(sliders.value)
    return { sliders }
  }
}
</script>
<style scoped lang="less">
.home-banner {
  width: 100vw; //ViewPoint Width,视窗宽度 1vm=视窗宽度1%
  position: relative;
  display: flex;//display 属性设置元素是否被视为块或者内联元素以及用于子元素的布局
  //FlexFlexible Box的缩写，意为”弹性布局”，用来为盒状模型提供最大的灵活性
  // 设为Flex布局以后，子元素的float、clear和vertical-align属性将失效

  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  z-index: 98;
  // background-size: cover;
  // background-position: ;
  .text{
    position: absolute;
    left: 35vw;
    z-index: 1000;
    display:block;
    flex: 1;
    width: 50%;
    margin: 20px 0;
    .title{
      // top: 50vh;
      text-align: center;
      width: 550px;
      height: 100px;
      color: #fff;
    }
    .sub{
      width: 600px;
      height: 1000px;
      color: #fff;
      text-align: center;
    }
  }
}
// 覆盖样式
.base-carousel {
  :deep(.carousel-btn.prev){
    // left: 270px;
  }
  :deep(.carousel-indicator) {
    // padding-left: 250px;
    position: absolute;
    transform: translate(-50%);
  }
  :deep(.carousel-btn.prev){
    left:10px;
  }
}
</style>
