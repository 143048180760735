<template>
  <div class="home-ai" style="padding-top: 100px;">
    <HomePanel title=" " sub-title=" ">
      <div ref="target" style="position: relative;height: 400px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <div style="position: relative;">
            <!-- <RouterLink to="/"> -->
              <img
                class="background"
                src="@\assets\images\pic3.jpg"
                alt
              />
              <div class="meta" style="position: absolute;">
                <p class="title">
                  <span class="top ellipsis">AI行业解决方案</span>
                  <span class="sub ellipsis">定制化AI解决方案</span>
                </p>
                <div class="aiList">
                  <div class="ai-item"
                  v-for="(item,index) in technologyList"
                  :key="index">
                  <img :src="item.picSrc" alt="" style="width: 100px;height: 100px;">
                  <div class="text">{{ item.text }}</div>
                </div>

                </div>
              </div>
            <!-- </RouterLink> -->
          </div>
        </Transition>
      </div>
    </HomePanel>
  </div>
</template>
<script>
import HomePanel from './home-panel';
// import Icon1 from '@/assets/image/icon2_01.png';

// let imgs = reactive([Icon1])
// console.log(imgs)
export default {
  name: 'HomeAI',
  components: { HomePanel },
  setup () {
    const { target, result } = {
      target: "操作成功",
      result: [
        {
          id: "1",
          title: "智能制造AIoT解决方案",
          summary: "从硬件底层开发自己的IoT网关",
          image: "@\assets\images\icon-1_01.png",
        },
        {
          id: "2",
          title: "产品智能化IoT解决方案",
          summary: "基于我们的智能网关搭建应用程序",
          image: "@\assets\images\icon-1_02.png",
        },
        {
          id: "3",
          title: "产业链IoT解决方案",
          summary: "工业区块链",
          image: "@\assets\images\icon-1_03.png",
        },
        {
          id: "4",
          title: "场景解决方案",
          summary: "基于场景化解决方案",
          image: "icon-1_04.png",
        },
      ],
    };
    const { technologyList } = {

      technologyList: [
        {
					picSrc: 'icon2_01.png',
					text: "边缘计算"
				}, 
        {
					picSrc: 'icon2_02.png',
					text: "数据采集网关"
				},
         {
					picSrc: 'icon2_03.png',
					text: "RFID技术"
				},
         {
					picSrc: 'icon2_04.png',
					text: "嵌入式技术"
				}, {
					picSrc: 'icon2_05.png',
					text: "鸿蒙系统定制"
				}, {
					picSrc: 'icon2_06.png',
					text: "非标定制"
				},
       ]
    };
    
    return { aiList: result ,technologyList};
    
  }
  
}
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 406px;
  li {
    width: 1600px;
    height: 450px;
    background: #fff;

    .hoverShadow();
    img {
      width: 1240px;
      height: 500px;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
}
.meta {
    background-image: linear-gradient(
      to top,
    //   rgba(0, 0, 0, 0.8),
      transparent 50%
    );
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    height: 188px;
    z-index: 10;
    display: flex;
    .title {
      // position: absolute;
      bottom: 0px;
      left: 0;
      //修改元素的左填充
      padding-left: 120px;
      width: 70%;
      height:180px;
      .top {
        color: #fff;
        left: 10px;
        font-size: 38px;
        display: block;
      }
      .sub {
        display: block;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
    .aiList{
  
    width: 50%;
    text-align: center;
    color: #fff;
    .ai-item{
      display:inline-block;
      flex: 1;
      width: 30%;
      margin: 20px 0;
      .text{
        font-size: 18px;

      }
    }
  }
  }
 
</style>
