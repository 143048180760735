<template>
  <!-- 首页入口 -->
  <HomeBanner />
  <!-- <div class="home-entry"> -->
    <!-- -->
    <!-- <div class="container"> -->
      <!-- 左侧分类 -->
      <!-- <HomeMenu /> -->
      <!-- <HomeCategory /> -->
      <!-- 轮播图 -->
    
    <!-- </div> -->
  <!-- </div> -->
  <div class="home-components">
    <homeSmartProduct />
    <!-- <HomeNew /> -->
    <!-- 智能物联网行业解决方案图片 -->
    <HomeSmartThings />
    <!-- 智能物联网行业解决方案图片 -->
   <HomeAI/>
    <!-- <HomeBrand /> -->
    <!-- 产品区块 -->
    <!-- <HomeProduct /> -->
    <!-- 最新专题 -->
    <HomeSpecial />
  </div>
</template>
<script>
// import HomeCategory from './components/home-category'
import HomeBanner from './components/home-banner'
import HomeNew from './components/home-new'
import HomeBrand from './components/home-brand'
import HomeSpecial from './components/home-special'
import HomeSmartThings from './components/home-smartThings.vue'
import HomeAI from './components/home-ai.vue'
import homeSmartProduct from './components/home-smartProduct.vue'
export default {
  name: 'HomePage',
  components: {
    HomeBanner,
    HomeNew,
    homeSmartProduct,
    HomeSmartThings,
    HomeAI,
    HomeBrand,
    HomeSpecial
  }
}
</script>
<style scoped lang="less">
  .home-entry{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  .home-components{
    display:flex;
    justify-content:space-between;
    flex-direction: column;
  }

</style>
