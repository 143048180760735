<template>
  <div class="home-new" style="padding-top: 10px;">
    <HomePanel title=" " sub-title=" ">
      <!-- 修改高度height为400 -->
      <div ref="target" style="position: relative;height: 400px;">
        <!-- 面板内容 -->
        <Transition name="fade">
          <ul  class="goods-list">
            <li>
              <RouterLink :to="`/`">
                <img  src="@\assets\images\smartProduct-1021.png" alt=""> 
                <p class="word" >
                  智能终端产品解决方案</p>
                  <!--<p class="name ellipsis">前后端功能组件化</p> -->
                <!-- <p class="price">&yen;</p> -->
              </RouterLink>
            </li>
          </ul>
          <!--  -->
        </Transition>
        <RouterLink to="/">
          <img src="@\assets\images\smartProduct-1021.png" alt />
          <div class="meta">
            <p class="title">
              <span class="top ellipsis">智能终端产品解决方案</span>
              <span class="sub ellipsis">这是简介</span>
            </p>
          </div>
        </RouterLink>
      </div>
    </HomePanel>
  </div>
</template>
<script>
import HomePanel from './home-panel'
export default {
  name: 'HomeNew',
  components: { HomePanel },
  setup () {
  }
}
</script>
<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 0px;
  li {
    width: 1400px;
    height: 500px;
    background: #fff;
    
    .hoverShadow();
    img {
      width: 1240px;
      height: 500px;
      position:relative;
      z-index: 1;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
  .home.new.word{
    position:absolute;
    top: 50%;
    left:50%; 
    transform:translate(-50%,-50%);
    color: white;
    font-size: 22px;
    text-align: center;
    z-index: 1;
  }
  .meta {
        background-image: linear-gradient(to top,rgba(0, 0, 0, 0.8),transparent 50%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 288px;
        .title {
          position: absolute;
          bottom: 0px;
          left: 0;
          padding-left: 16px;
          width: 70%;
          height: 70px;
          .top {
            color: #fff;
            font-size: 22px;
            display: block;
          }
          .sub {
            display: block;
            font-size: 19px;
            color: #999;
          }
        }
        .price {
          position: absolute;
          bottom: 25px;
          right: 16px;
          line-height: 1;
          padding: 4px 8px 4px 7px;
          color: @priceColor;
          font-size: 17px;
          background-color: #fff;
          border-radius: 2px;
        }
      }
}
</style>
