<template>
  <HomePanel title=" " style="padding-top: 100px;">
    <template v-slot:right>
      <!--副标题栏 可指向不同专题子页面-->
      <!--
      <div class="sub">
        <RouterLink v-for="sub in cate.result" :key="sub.id" :to="`/category/sub/${sub.id}`">{{sub.title}}</RouterLink>
      </div>
      -->
      <BaseMore :path="`/category/${cate.id}`" />
    </template>
    <div ref="target" class="special-list">
      <div class="special-item" v-for="item in specialList" :key="item.id">
        <!-- <RouterLink to="/"> -->
        <div class="item">
          <div item-image>
            <img :src="item.cover" alt />
          </div>
          <div class="meta">
            <p class="title">
              <span class="top ellipsis">{{item.title}}</span>
              <span class="sub ellipsis">{{item.summary}}</span>
            </p>
          </div>
        </div>
      <!-- </RouterLink> -->
      </div>
    </div>
  </HomePanel>
</template>

<script>
import { findSpecial } from '@/api/home'
import HomePanel from './home-panel'
import { useLazyData } from '@/hooks'
export default {
  name: 'HomeSpecial',
  components: { HomePanel },
  setup () {
    // const { target, result } = useLazyData(findSpecial)
    const { target, result } = {
    "target": "操作成功",
    "result": [
        {
            "id": "1351770184914280450",
            "title": "物联网IOT产品",
            "summary": "从硬件底层开发自己的IOT网关",
            //原网上路径 https://ask.dcloud.net.cn/uploads/avatar/001/67/43/81_avatar_max.jpg
            "cover": "pro_01.png",
            "lowestPrice": 89,
            "collectNum": 1230,
            "viewNum": 4526,
            "replyNum": 265,
            "desc": "good",
            "place": "中国江苏传智"
        },
        {
            "id": "1351770184914280450",
            "title": "智慧殡葬",
            "summary": "基于我们的智能网关搭建应用程序",
            "cover": "pro_02.png",
            "lowestPrice": 89,
            "collectNum": 1230,
            "viewNum": 4526,
            "replyNum": 265,
            "desc": "good",
            "place": "中国江苏传智"
        },
        {
            "id": "1351770184914280450",
            "title": "工控电脑",
            "summary": "工业区块链",
            "cover": "pro_03.png",
            "lowestPrice": 89,
            "collectNum": 1230,
            "viewNum": 4526,
            "replyNum": 265,
            "desc": "good",
            "place": "中国江苏传智"
        },
        {
            "id": "1351770184914280450",
            "title": "场景解决方案",
            "summary": "全场景解决方案 ",
            "cover": "pro_04.png",
            "lowestPrice": 89,
            "collectNum": 1230,
            "viewNum": 4526,
            "replyNum": 265,
            "desc": "good",
            "place": "中国江苏传智"
        }

    ]
}
const cate = {
    "result": [
        {
            "id": "49",
            "title": "物联网IOT产品",
        },
        {
            "id": "50",
            "title": "智能殡葬",
        },
        {
            "id": "51",
            "title": "工控电脑",
        },
        {
            "id": "52",
            "title": "场景解决方案",
        }
    ]
}

    return { cate,target, specialList: result }
  }
}
</script>

<style scoped lang='less'>
.home-panel {
  background: #f5f5f5;
}
.sub {
    margin-bottom: 2px;
    a {
      padding: 2px 12px;
      font-size: 16px;
      border-radius: 4px;
      &:hover {
        background: @baseColor;
        color: #fff;
      }
      &:last-child {
        margin-right: 80px;
      }
    }
  }
.special-list {
  height: 380px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .special-item {
    width: 305px;
    background: #fff;
    border:1px solid white;
    text-align: center;
    border-radius: 15px;
    .hoverShadow();
    .item {
      display: block;
      width: 100%;
      height: 288px;
      position: relative;
      
      img {
        border-radius: 15px;
        width: 100%;
        height: 300px;
        object-fit: cover;
        background-color: #fff;
        // filter: blur(1px) contrast(150%);
      }
      .meta {
        // background-image: linear-gradient(to top,rgba(0, 0, 0, 0.8),transparent 50%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 300px;
        .title {
          text-align: center;
          position: absolute;
          bottom: 0px;
          left: 0;
          padding-left: 16px;
          width: 85%;
          height: 0px;
          .top {
            color: #000;
            font-size: 18px;
            display: block;
          }
          .sub {
            display: block;
            font-size: 12px;
            color: #999;
          }
        }
        .price {
          position: absolute;
          bottom: 25px;
          right: 16px;
          line-height: 1;
          padding: 4px 8px 4px 7px;
          color: @priceColor;
          font-size: 17px;
          background-color: #fff;
          border-radius: 2px;
        }
      }
    }
    .foot {
      height: 72px;
      line-height: 72px;
      padding: 0 20px;
      font-size: 16px;

      i {
        display: inline-block;
        width: 15px;
        height: 14px;
        margin-right: 5px;
        color: #999;
      }
      .like,
      .view {
        float: left;
        margin-right: 25px;
        vertical-align: middle;
      }
      .reply {
        float: right;
        vertical-align: middle;
      }
    }
  }
}
</style>
