<template>
    <!-- height: 500px; -->
  <div class="home-smartProdcut" style="padding-top: 10px;">
    <HomePanel title=" " sub-title=" ">
      <!-- 修改高度height为400 -->
      <div ref="target" style="position: relative; height: 400px">
        <!-- 面板内容 -->
        <Transition name="fade">
          <div style="position: relative;">
            <!-- <RouterLink to="/"> -->
              <img
                class="background"
                src="@\assets\images\pic1.jpg"
                alt
              />
              <div class="meta" style="position: absolute;">
                <p class="title">
                  <span class="top ellipsis">智能终端产品解决方案</span>
                  <span class="sub ellipsis">携手并进，资源共享，跨行业跨领域赋能</span>
                </p>
              </div>
            <!-- </RouterLink> -->
          </div>
        </Transition>
      </div>
    </HomePanel>
  </div>
</template>
  <script>
import HomePanel from "./home-panel";
export default {
  name: "HomeSmartProduct",
  components: { HomePanel },
  setup() {},
};
</script>
  <style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 0px;
  li {
    width: 1400px;
    height: 500px;
    background: #fff;

    .hoverShadow();
    img {
      width: 1240px;
      height: 500px;
      position: relative;
      z-index: 1;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
  .home.new.word {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 22px;
    text-align: center;
    z-index: 1;
  }
  
}
.meta {
    background-image: linear-gradient(
      to top,
    //   rgba(0, 0, 0, 0.8),
      transparent 50%
    );
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    height: 188px;
    z-index: 10;
    .title {
      position: absolute;
      bottom: 0px;
      left: 0;
      //修改元素的左填充
      padding-left: 120px;
      width: 70%;
      height: 180px;
      .top {
        color: #fff;
        left: 10px;
        font-size: 38px;
        display: block;
      }
      .sub {
        display: block;
        font-size: 18px;
        color: #FFFFFF;
      }
    }
  }
</style>
  