<template>
  <div class="home-smartTings" style="padding-top: 100px">
    <HomePanel title=" " sub-title=" ">
      <div ref="target" style="position: relative; height: 400px">
        <!-- 面板内容 -->
        <Transition name="fade">
          <div style="position: relative">
            <!-- <router-link to=""> -->
              <img class="background" src="@\assets\images\pic2.jpg" alt="" />
            <!-- </router-link> -->
            <div class="meta" >
              <p class="title">
                <span class="top ellipsis">智能物联网行业解决方案</span>
                <span class="sub ellipsis"
                  >创新型物联网产品和解决方案供应商</span
                >
              </p>
              <div class="smartThings">
                <div
                  class="smartThings-item"
                  v-for="item in smartThingsList"
                  :key="item.id"
                  style=""
                >
                  <img
                    :src="item.image"
                    alt=""
                    style="width: 150px; height: 150px;display: block;margin: 0 auto;"
                  />
                  <p style="display: block">
                    <span>{{ item.title }}</span
                    ><br />
                    <span style="color: #999;">{{ item.summary }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </HomePanel>
  </div>
</template>

<script>
import HomePanel from "./home-panel";
export default {
  name: "HomeSmartThings",
  components: { HomePanel },
  setup() {
    const { target, result } = {
      target: "操作成功",
      result: [
        {
          id: "1",
          title: "智能制造AIoT解决方案",
          summary: "从硬件底层开发自己的IoT网关",
          image: "icon1_01.png",
        },
        {
          id: "2",
          title: "产品智能化IoT解决方案",
          summary: "基于我们的智能网关搭建应用程序",
          image: "icon1_02.png",
        },
        {
          id: "3",
          title: "产业链IoT解决方案",
          summary: "工业区块链",
          image: "icon1_03.png",
        },
        {
          id: "4",
          title: "场景解决方案",
          summary: "基于场景化解决方案",
          image: "icon1_04.png",
        },
      ],
    };
    return { smartThingsList: result };
  },
};
</script>

<style scoped lang="less">
.goods-list {
  display: flex;
  justify-content: space-between;
  height: 406px;
  li {
    width: 1600px;
    height: 450px;
    background: #fff;

    .hoverShadow();
    //图片设置
    img {
      width: 200px;
      height: 200px;
    }
    p {
      font-size: 22px;
      padding: 12px 30px 0 30px;
      text-align: center;
    }
    .price {
      color: @priceColor;
    }
  }
}
.meta {
  background-image: linear-gradient(
    to top,
    // rgba(0, 0, 0, 0.8),
    transparent 50%
  );
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
  height: 188px;
  z-index: 10;
  .title {
    // position: absolute;
    bottom: 0px;
    left: 0;
    //修改元素的左填充
    padding-left: 120px;
    width: 70%;
    // height: 180px;
    margin-bottom: 20px;
    .top {
      color: #fff;
      left: 10px;
      font-size: 38px;
      display: block;
    }
    .sub {
      display: block;
      font-size: 18px;
      color: #ffffff;
    }
  }
  .smartThings {
    display: flex;
  }
  .smartThings-item {
    flex: 1;
    width: 400px;
    height: 188px;
    // position: relative;
    display: flex;
    bottom: 0px;
    left: 30px;
    flex-direction: column;
    text-align: center;
    color:#fff;
    // padding-left: 20px;
    .hoverShadow();
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      left: 0;
      top: 275px;
      width: 70%;
      height: 188px;
      bottom: 0px;
      padding-left: 16px;

      .text-top {
        color: #fff;
        font-size: 16px;
        display: block;
      }
      .text-bottom {
        color: #999;
        font-size: 12px;
        display: block;
      }
    }
  }
}
</style>
